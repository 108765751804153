<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="8" xl="5">
                <v-card class="axessia-card fill-height" outlined>
                    <v-toolbar class="mb-0 ma-4" dense flat>
                        <v-toolbar-title class="primary--text title">
                            Mon compte
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="px-4">
                        <ValidationObserver ref="observer">
                            <ValidationProvider v-slot="{ errors }" name="Ancien mot de passe" rules="required">
                                <v-text-field v-model="oldPassword" type="password" label="Ancien mot de passe" :error-messages="errors" outlined dense />
                            </ValidationProvider>

                            <ValidationProvider v-slot="{ errors }" name="Nouveau mot de passe" rules="required" vid="confirmInput">
                                <v-text-field v-model="newPassword" type="password" label="Nouveau mot de passe" :error-messages="errors" outlined dense />
                            </ValidationProvider>

                            <ValidationProvider v-slot="{ errors }" name="Confirmation" rules="required|confirmed:confirmInput">
                                <v-text-field v-model="confirmPassword" type="password" label="Confirmation" :error-messages="errors" outlined dense />
                            </ValidationProvider>
                        </ValidationObserver>

                        <div class="d-flex">
                            <v-btn @click="submit()" class="ml-auto" color="primary" small>
                                Envoyer
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'MyAccount',

    data: () => ({
        oldPassword: null,
        newPassword: null,
        confirmPassword: null
    }),

    methods: {
        async submit() {
            try {
                this.setLoading(true);
                const valid = await this.$refs.observer.validate();
                if (!valid) {
                    return;
                }

                const body = {
                    user: {
                        oldPassword: this.oldPassword,
                        newPassword: this.newPassword
                    }
                };
                const { success, err } = await this.repos.auth.updateMe(body);
                if (success) {
                    this.$notify({
                        title: 'Succès',
                        text: 'Votre mot de passe a bien été modifié',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la modification du mot de passe',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>
